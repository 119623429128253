import Aromanize from "aromanize";
import { korean } from "./data";

Aromanize.rules.hangul.sauka = korean;

const toHangul = (text) => {
  return text;
};

function koreanToBelarusian(text) {
  const result = toHangul(text);

  return Aromanize.toLatin(result, "sauka")
    .replaceAll("чі", "чы")
    .replaceAll("че", "чэ")
    .replaceAll("чё", "чо")
    .replaceAll("чя", "ча")
    .replaceAll("рі", "ры")
    .replaceAll("ре", "рэ")
    .replaceAll("рё", "ро")
    .replaceAll("ря", "ра");
}

export { koreanToBelarusian };

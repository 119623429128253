import { alpha, capitalize, createTheme, darken, lighten } from "@mui/material";
import React, { createContext, useContext, useMemo, useState } from "react";
import { ThemeProvider } from "@mui/material";
import {
  argbFromHex,
  hexFromArgb,
  themeFromSourceColor,
} from "@material/material-color-utilities";

const primaryColor = "#FF450B";
const ColorModeContext = createContext({
  mode: "light",
  toggleColorMode: () => {},
});

export const useColorMode = () => useContext(ColorModeContext);

export const getDesignTokens = (mode, scheme, tones) => {
  return {
    palette: {
      mode,
      primary: {
        main: scheme.primary,
        contrastText: scheme.onPrimary,
      },
      onPrimary: {
        main: scheme.onPrimary,
        contrastText: scheme.primary,
      },
      primaryContainer: {
        main: scheme.primaryContainer,
        contrastText: scheme.onPrimaryContainer,
      },
      onPrimaryContainer: {
        main: scheme.onPrimaryContainer,
        contrastText: scheme.primaryContainer,
      },
      secondary: {
        main: scheme.secondary,
        contrastText: scheme.onSecondary,
      },
      onSecondary: {
        main: scheme.onSecondary,
        contrastText: scheme.secondary,
      },
      secondaryContainer: {
        main: scheme.secondaryContainer,
        contrastText: scheme.onSecondaryContainer,
      },
      onSecondaryContainer: {
        main: scheme.onSecondaryContainer,
        contrastText: scheme.secondaryContainer,
      },
      tertiary: {
        main: scheme.tertiary,
        contrastText: scheme.onTertiary,
      },
      onTertiary: {
        main: scheme.onTertiary,
        contrastText: scheme.tertiary,
      },
      tertiaryContainer: {
        main: scheme.tertiaryContainer,
        contrastText: scheme.onTertiaryContainer,
      },
      onTertiaryContainer: {
        main: scheme.onTertiaryContainer,
        contrastText: scheme.tertiary,
      },
      error: {
        main: scheme.error,
        contrastText: scheme.onError,
      },
      onError: {
        main: scheme.onError,
        contrastText: scheme.error,
      },
      errorContainer: {
        main: scheme.errorContainer,
        contrastText: scheme.onErrorContainer,
      },
      onErrorContainer: {
        main: scheme.onErrorContainer,
        contrastText: scheme.errorContainer,
      },
      background2: {
        main: scheme.background,
        contrastText: scheme.onBackground,
      },
      onBackground: {
        main: scheme.onBackground,
        contrastText: scheme.background,
      },
      surface: {
        main: scheme.surface,
        contrastText: scheme.onSurface,
      },
      onSurface: {
        main: scheme.onSurface,
        contrastText: scheme.surface,
      },
      surfaceVariant: {
        main: scheme.surfaceVariant,
        contrastText: scheme.onSurfaceVariant,
      },
      onSurfaceVariant: {
        main: scheme.onSurfaceVariant,
        contrastText: scheme.surfaceVariant,
      },
      inverseSurface: {
        main:
          scheme.inverseSurface ||
          (mode === "light" ? tones?.neutral[20] : tones?.neutral[90]),
        contrastText:
          scheme.inverseOnSurface ||
          (mode === "light" ? tones?.neutral[95] : tones?.neutral[20]),
      },
      inverseOnSurface: {
        main:
          scheme.inverseOnSurface ||
          (mode === "light" ? tones?.neutral[95] : tones?.neutral[20]),
        contrastText:
          scheme.inverseSurface ||
          (mode === "light" ? tones?.neutral[20] : tones?.neutral[90]),
      },
      inversePrimary: {
        main:
          scheme.inversePrimary ||
          (mode === "light" ? tones?.neutral[80] : tones?.neutral[40]),
        contrastText: scheme.primary,
      },

      surfaceTint: scheme.primary,
      outline: scheme.outline,
      shadow: scheme.shadow,

      background: {
        default: scheme.background,
        paper: scheme.surface,
      },
      common: {
        white: scheme.background,
        black: scheme.onBackground,
      },
      text: {
        primary: scheme.onSurface,
        secondary: scheme.onSecondaryContainer,
      },
      divider: scheme.outline,
    },
    tones,
  };
};

const generateTheme = (colorBase) => {
  const theme = themeFromSourceColor(argbFromHex(colorBase));

  const paletteTones = {};
  const light = {};
  const dark = {};

  for (const [key, palette] of Object.entries(theme.palettes)) {
    const tones = {};
    for (const tone of [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 99, 100]) {
      const color = hexFromArgb(palette.tone(tone));
      tones[tone] = color;
    }
    paletteTones[key] = tones;
  }

  for (const [key, value] of Object.entries(theme.schemes.light.toJSON())) {
    const color = hexFromArgb(value);
    light[key] = color;
  }
  for (const [key, value] of Object.entries(theme.schemes.dark.toJSON())) {
    const color = hexFromArgb(value);
    dark[key] = color;
  }
  for (const colorGroup of theme.customColors) {
    const key = colorGroup.color.name;

    light[key] = hexFromArgb(colorGroup.light.color);
    dark[key] = hexFromArgb(colorGroup.dark.color);

    light[`on${capitalize(key)}`] = hexFromArgb(colorGroup.light.onColor);
    dark[`on${capitalize(key)}`] = hexFromArgb(colorGroup.dark.onColor);
  }

  const scheme = {
    light,
    dark,
    tones: paletteTones,
  };

  return scheme;
};

export const WithThemeContext = ({ children }) => {
  const localStorageValue = localStorage?.getItem("mode");
  const [mode, setMode] = useState(localStorageValue || "dark");

  const colorMode = useMemo(
    () => ({
      mode,
      toggleColorMode: () => {
        const value = mode === "light" ? "dark" : "light";

        localStorage?.setItem("mode", value);
        setMode(value);
      },
    }),
    [mode]
  );

  const theme = useMemo(() => {
    const schema = generateTheme(mode === "dark" ? primaryColor : "#ff171c");

    const { palette } = getDesignTokens(mode, schema[mode], schema.tones);

    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", schema[mode].surface);

    return createTheme({
      palette,
      typography: {
        fontFamily: ["Montserrat"].join(","),
      },
      shape: {
        borderRadius: 12,
      },
      components: {
        MuiModal: {
          defaultProps: {
            disableScrollLock: true,
          },
        },
        MuiCssBaseline: {
          defaultProps: {
            enableColorScheme: true,
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              background:
                mode === "dark"
                  ? darken(palette.primary.main, 0.9)
                  : lighten(palette.primary.main, 0.9),
              color: palette.onSurface.main,
            },
            outlined: {
              borderColor: palette.outline,
              background: palette.surface.main,
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            root: {
              //background: palette.surface.main,
              //color: palette.onSurface.main,
            },
            paper: {
              border: "0px",
              //background: palette.mode == 'light' ? lighten(palette.primary.main, 0.85) : darken(palette.primary.main, 0.8),
              //color: palette.surface.contrastText,
              background: palette.surface.main,
              color: palette.onSurface.main,
            },
          },
        },
        MuiInputAdornment: {
          defaultProps: {
            sx: {
              ml: "4px",
            },
          },
        },

        MuiButtonBase: {
          defaultProps: {
            disableRipple: true, // No more ripple, on the whole application!
          },
        },
        MuiInputBase: {
          defaultProps: {
            sx: {
              borderRadius: 50,
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              background: palette.secondaryContainer.main,
              color: palette.secondaryContainer.contrastText,
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            root: {
              width: 42,
              height: 26,
              padding: 0,
              marginLeft: 12,
              marginRight: 8,
              "& .MuiSwitch-switchBase": {
                padding: 0,
                margin: 7,
                transitionDuration: "100ms",
                "&.Mui-checked": {
                  transform: "translateX(16px)",
                  margin: 4,
                  "& + .MuiSwitch-track": {
                    backgroundColor: palette.primary.main,
                    opacity: 1,
                    border: 0,
                  },
                  "& .MuiSwitch-thumb": {
                    color: palette.onPrimary.main,
                    width: 18,
                    height: 18,
                  },
                  /*'& .MuiSwitch-thumb:before': {
                              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                                  palette.primary.main,
                              )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                          },
                          '&.Mui-disabled .MuiSwitch-thumb:before': {
                              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                                  alpha(palette.onSurfaceVariant.main, 0.28),
                              )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                          },*/
                  "&.Mui-disabled + .MuiSwitch-track": {
                    backgroundColor: alpha(palette.onSurface.main, 0.1),
                  },
                  "&.Mui-disabled .MuiSwitch-thumb": {
                    color: alpha(palette.surface.main, 0.8),
                  },
                },
                "&.Mui-focusVisible .MuiSwitch-thumb": {
                  color: palette.primary.main,
                  border: `6px solid ${palette.primary.contrastText}`,
                },
                "&.Mui-disabled .MuiSwitch-thumb": {
                  color: alpha(palette.onSurface.main, 0.3),
                },
              },
              "& .MuiSwitch-thumb": {
                boxSizing: "border-box",
                color: palette.outline,
                width: 12,
                height: 12,
                "&:before": {
                  content: "''",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: 0,
                  top: 0,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                },
              },
              "& .MuiSwitch-track": {
                borderRadius: 26 / 2,
                border: `1px solid ${palette.outline}`,
                backgroundColor: palette.surfaceVariant.main,
                opacity: 1,
                transition: createTheme().transitions.create(
                  ["background-color"],
                  {
                    duration: 500,
                  }
                ),
              },
            },
          },
        },
        MuiSnackbarContent: {
          styleOverrides: {
            root: {
              backgroundColor: palette.inverseSurface.main,
            },
            message: {
              color: palette.inverseOnSurface.main,
            },
            action: {
              color: palette.inversePrimary.main,
            },
          },
        },
        MuiTableContainer: {
          styleOverrides: {
            root: {
              borderColor: `${palette.divider}!important`,
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              borderColor: palette.divider,
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: "24px",
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: "24px",
            },
          },
          variants: [
            // {
            //   props: { variant: "elevation" },
            //   style: {
            //     boxShadow: createTheme().shadows[1],
            //     backgroundColor: alpha(palette.primary.main, 0.05),
            //     transition: createTheme().transitions.create(
            //       ["background-color", "box-shadow", "border-color", "color"],
            //       {
            //         duration: createTheme().transitions.duration.short,
            //       }
            //     ),
            //     "&:hover": {
            //       boxShadow: createTheme().shadows[2],
            //       background: alpha(palette.primary.main, 0.08),
            //     },
            //   },
            // },
            {
              props: { variant: "filled" },
              style: {
                border: "none",
                backgroundColor: alpha(palette.primary.main, 0.08),
                transition: createTheme().transitions.create(
                  ["background-color", "box-shadow", "border-color", "color"],
                  {
                    duration: createTheme().transitions.duration.short,
                  }
                ),
              },
            },
            // {
            //   props: { variant: "outlined" },
            //   style: {
            //     backgroundColor: palette.surface.main,
            //     borderColor: palette.outline,
            //     transition: createTheme().transitions.create(
            //       ["background-color", "box-shadow", "border-color", "color"],
            //       {
            //         duration: createTheme().transitions.duration.short,
            //       }
            //     ),
            //     "&:hover": {
            //       boxShadow: createTheme().shadows[1],
            //       background: alpha(palette.onSurface.main, 0.05),
            //     },
            //   },
            // },
          ],
        },
      },
    });
  }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

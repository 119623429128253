import * as React from "react";
import ReactDOM from "react-dom";

import { WithThemeContext } from "./theme";
import * as ServiceWorker from "./serviceWorker";
import App from "./Application";
import CssBaseline from "@mui/material/CssBaseline";
import { FiltersContext } from "./context/filters";
import { SettingsContext } from "./context/settings";
import Privacy from "./Privacy";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const defaultFilters = {
  "ru-be": true,
  "be-ru": true,
  "en-be": true,
  tsbm: true,
  grammer: true,
  phonetic: true,
};

const defaultSettings = {
  keyboardHelpers: true,
  autoSearch: true,
  language: "be",
};

function Application() {
  const [settings, setSettings] = React.useState(
    JSON.parse(
      localStorage.getItem("settings") || JSON.stringify(defaultSettings)
    )
  );

  const [filters, setFilters] = React.useState(
    JSON.parse(
      localStorage.getItem("filters") || JSON.stringify(defaultFilters)
    )
  );

  React.useEffect(() => {
    const splash = document.getElementById("splash");
    if (splash) {
      splash.classList.add("hidden");
      setTimeout(() => {
        splash.remove();
      }, 1000);
    }
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        change: (nextState) => {
          setSettings(nextState);
        },
      }}
    >
      <FiltersContext.Provider
        value={{
          ...filters,
          change: (nextState) => {
            setFilters(nextState);
          },
        }}
      >
        <CssBaseline />
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>
      </FiltersContext.Provider>
    </SettingsContext.Provider>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <WithThemeContext>
      <Application />
    </WithThemeContext>
  </BrowserRouter>,
  document.querySelector("#root")
);

ServiceWorker.register();

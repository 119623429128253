import React, { useCallback, useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ContentCopyOutlined, TranslateRounded } from "@mui/icons-material";

import { useWindowSize } from "./hooks/useWindowSize";
import { AppMenu } from "./AppMenu";

import { useSettings } from "./context/settings";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { japaneseToBelarusian } from "./translits/japanese";
import { chineseToBelarusian } from "./translits/chinese";
import { koreanToBelarusian } from "./translits/korean";

const handlers = {
  japan: japaneseToBelarusian,
  chinese: chineseToBelarusian,
  korean: koreanToBelarusian,
};

const SUPPORT_LANGUAGES = ["japan", "chinese", "korean"];

const lngTexts = {
  japan: "Японская",
  chinese: "Кітайская",
  korean: "Карэйская",
};

const lngPlaceholder = {
  japan: "Naruto Uzumaki",
  chinese: "Напішыце pinyin па складах",
  korean: "BTS 전정국",
};

const lngAuthor = {
  japan: "Паводле транскрыпцыі Змітра Саўкі",
  korean: "Паводле транскрыпцыі Змітра Саўкі",
  chinese: "Паводле кітайска-беларускага слоўніка",
};

const LanguageSwticher = ({ onValueChange, language }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (lng) => {
    handleClose();

    if (onValueChange) {
      onValueChange(lng);
    }
  };

  return (
    <>
      <IconButton
        sx={{
          borderRadius: "4px",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          p: 0,
          m: 0.6,
        }}
        disableTouchRipple
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img
          src={`/flags/${language}.svg`}
          alt="logo"
          style={{ borderRadius: 4, width: 32 }}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {SUPPORT_LANGUAGES.map((lng) => {
          return (
            <MenuItem
              onClick={() => handleChange(lng)}
              key={lng}
              sx={{ my: 1, py: 1 }}
            >
              <ListItemIcon>
                <Box
                  sx={{
                    borderRadius: "4px",
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    display: "flex",
                  }}
                >
                  <img
                    src={`/flags/${lng}.svg`}
                    alt="logo"
                    style={{ borderRadius: 4, width: 32 }}
                  />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={lngTexts[lng]}
                sx={{ ml: 1 }}
                primaryTypographyProps={{
                  sx: {
                    fontSize: 16,
                  },
                }}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default function App() {
  const { width } = useWindowSize();
  const location = useLocation();
  const params = qs.parse(location.search.substring(1));

  const settings = useSettings();
  const inputRef = useRef();
  const [search, setSearch] = useState(params?.q || "");
  const [isLoading, setLoading] = useState(false);

  const [languageValue, setLanguageValue] = useState("japan");
  const [result, setResult] = useState();
  const [showDefinition, setShowDefinition] = useState(null);

  const handleChange = useCallback(
    async (e) => {
      const {
        target: { value },
      } = e;

      setSearch(value);
      if (result) {
        setResult(null);
      }

      if (showDefinition) {
        setShowDefinition(null);
      }
    },
    [result, showDefinition]
  );

  const handleSearch = useCallback(async () => {
    if (search) {
      setLoading(true);

      const handler = handlers[languageValue];
      const result = handler(search);
      setResult(result);
      setLoading(false);
    }
  }, [search, languageValue]);

  useEffect(() => {
    if (settings?.autoSearch === false) {
      return;
    }

    const delayDebounceFn = setTimeout(handleSearch, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [handleSearch, settings]);

  const changeStyles = width > 650;

  return (
    <Container maxWidth="sm" sx={{ position: "relative" }}>
      {params?.embed !== "1" && (
        <>
          <Box
            sx={{
              position: "absolute",
              right: !changeStyles ? 8 : 16,
              top: !changeStyles ? 8 : 16,
            }}
          >
            <AppMenu />
          </Box>
        </>
      )}
      <Box
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          pt: 4,
        }}
      >
        {params?.embed !== "1" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              pt: 4,
              mb: 4,
            }}
          >
            <img
              src="/logo.svg"
              alt="logo"
              width={!changeStyles ? 40 : 80}
              style={{ borderRadius: 50 }}
            />
            <Typography variant="h4" sx={{ ml: 2 }}>
              Трансліт
            </Typography>
          </Box>
        )}

        <TextField
          placeholder={lngPlaceholder[languageValue]}
          fullWidth
          value={search}
          autoCapitalize="none"
          autoCorrect="none"
          autoSave="none"
          autoFocus
          autoComplete="new-password"
          onChange={handleChange}
          helperText="Транслітэрацыя іншамоўных найменняў на беларускую мову"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearch();
            }
          }}
          inputRef={inputRef}
          InputProps={{
            sx: { borderRadius: 50 },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={!isLoading ? handleSearch : undefined}>
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <TranslateRounded />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <LanguageSwticher
                  onValueChange={setLanguageValue}
                  language={languageValue}
                />
              </InputAdornment>
            ),
          }}
        />
        {!!result && (
          <>
            <Card sx={{ width: "100%", mt: 4 }} variant="outlined">
              <CardHeader
                action={
                  <IconButton
                    aria-label="copy"
                    onClick={() => {
                      navigator.clipboard.writeText(result);
                    }}
                  >
                    <ContentCopyOutlined />
                  </IconButton>
                }
                title={result}
                subheader={
                  <Typography variant="body2">
                    {lngAuthor[languageValue]}
                  </Typography>
                }
              />
            </Card>
          </>
        )}
      </Box>
    </Container>
  );
}

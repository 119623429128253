export const korean = {
  cho: {
    ᄀ: "к",
    ᄁ: "к",
    ᄂ: "н",
    ᄃ: "т",
    ᄄ: "т",
    ᄅ: "р",
    ᄆ: "м",
    ᄇ: "п",
    ᄈ: "пп",
    ᄉ: "с",
    ᄊ: "сс",
    ᄋ: "",
    ᄌ: "ч",
    ᄍ: "ч",
    ᄎ: "чх",
    ᄏ: "кх",
    ᄐ: "тх",
    ᄑ: "пх",
    ᄒ: "х",
  },

  jung: {
    ᅡ: "а",
    ᅢ: "э",
    ᅣ: "я",
    ᅤ: "я",
    ᅥ: "о",
    ᅦ: "э",
    ᅧ: "ё",
    ᅨ: "е",
    ᅩ: "о́",
    ᅪ: "ва",
    ᅫ: "вэ",
    ᅬ: "ве",
    ᅭ: "ё",
    ᅮ: "у",
    ᅯ: "во",
    ᅰ: "ве",
    ᅱ: "ві",
    ᅲ: "ю",
    ᅳ: "ы",
    ᅴ: "ый",
    ᅵ: "і",
  },

  jong: {
    ᆨ: "к",
    ᆨᄋ: "г",
    ᆨᄂ: "н",
    ᆨᄅ: "н",
    ᆨᄆ: "нм",
    ᆩ: "к",
    ᆩᄋ: "к",
    ᆩᄂ: "н",
    ᆩᄅ: "н",
    ᆩᄆ: "нм",
    ᆪ: "к",
    ᆪᄋ: "кс",
    ᆪᄂ: "н",
    ᆪᄅ: "н",
    ᆪᄆ: "нм",
    ᆪᄒ: "ксх",
    ᆫ: "н",
    ᆫᄀ: "нг",
    ᆫᄃ: "нд",
    ᆫᄅ: "к",
    ᆫᄇ: "нб",
    ᆫᄌ: "ндж",
    ᆬ: "н",
    ᆬᄋ: "нч",
    ᆬᄂ: "н",
    ᆬᄅ: "н",
    ᆬᄆ: "нм",
    ᆬㅎ: "нчх",
    ᆭ: "н",
    ᆭᄋ: "нх",
    ᆭᄅ: "н",
    ᆮ: "т",
    ᆮᄂ: "тн",
    ᆮᄅ: "н",
    ᆮᄆ: "нм",
    ᆯ: "л",
    ᆯᄋ: "р",
    ᆯᄀ: "лг",
    ᆯᄂ: "к",
    ᆯᄃ: "лт",
    ᆯᄅ: "к",
    ᆯᄇ: "лб",
    ᆯᄉ: "лсс",
    ᆯᄌ: "лч",
    ᆯᄒ: "рх",
    ᆰ: "к",
    ᆰᄋ: "лг",
    ᆰᄀ: "лг",
    ᆰᄂ: "н",
    ᆰᄅ: "н",
    ᆰᄆ: "нм",
    ᆰᄒ: "лкх",
    ᆱ: "м",
    ᆱᄋ: "лм",
    ᆱᄂ: "мн",
    ᆱᄅ: "мн",
    ᆱᄆ: "мм",
    ᆱᄒ: "лмх",
    ᆲ: "п",
    ᆲᄋ: "лп",
    ᆲᄂ: "мн",
    ᆲᄅ: "мн",
    ᆲᄆ: "мм",
    ᆲᄒ: "лпх",
    ᆳ: "т",
    ᆳᄋ: "лс",
    ᆳᄂ: "н",
    ᆳᄅ: "н",
    ᆳᄆ: "мн",
    ᆳᄒ: "лс",
    ᆴ: "т",
    ᆴᄋ: "лтх",
    ᆴᄂ: "н",
    ᆴᄅ: "н",
    ᆴᄆ: "нм",
    ᆴᄒ: "лтх",
    ᆵ: "п",
    ᆵᄋ: "лпх",
    ᆵᄂ: "мн",
    ᆵᄅ: "мн",
    ᆵᄆ: "мм",
    ᆵᄒ: "лпх",
    ᆶ: "л",
    ᆶᄋ: "лх",
    ᆶᄂ: "к",
    ᆶᄅ: "к",
    ᆶᄆ: "лм",
    ᆶᄒ: "лх",
    ᆷ: "м",
    ᆷᄀ: "мг",
    ᆷᄃ: "мд",
    ᆷᄅ: "мн",
    ᆷᄇ: "мб",
    ᆷᄌ: "мдж",
    ᆸ: "п",
    ᆸᄋ: "б",
    ᆸᄂ: "мн",
    ᆸᄅ: "мн",
    ᆸᄆ: "мм",
    ᆹ: "п",
    ᆹᄋ: "пс",
    ᆹᄂ: "мн",
    ᆹᄅ: "мн",
    ᆹᄆ: "мм",
    ᆺ: "т",
    ᆺᄋ: "с",
    ᆺᄂ: "н",
    ᆺᄅ: "н",
    ᆺᄆ: "нм",
    ᆺᄒ: "с",
    ᆻ: "т",
    ᆻᄋ: "сс",
    ᆻᄅ: "тн",
    ᆻᄆ: "нм",
    ᆻᄒ: "тх",
    ᆼ: "н",
    ᆼᄀ: "нг",
    ᆼᄃ: "нд",
    ᆼᄅ: "н",
    ᆼᄇ: "нб",
    ᆼᄌ: "ндж",
    ᆽ: "т",
    ᆽᄋ: "ч",
    ᆽᄂ: "н",
    ᆽᄅ: "н",
    ᆽᄆ: "нм",
    ᆽᄒ: "чх",
    ᆾ: "т",
    ᆾᄋ: "чх",
    ᆾᄂ: "н",
    ᆾᄅ: "н",
    ᆾᄆ: "нм",
    ᆾᄒ: "чх",
    ᆿ: "к",
    ᆿᄋ: "кх",
    ᆿᄂ: "н",
    ᆿᄅ: "н",
    ᆿᄆ: "нм",
    ᆿᄒ: "кх",
    ᇀ: "т",
    ᇀᄋ: "тх",
    ᇀᄂ: "н",
    ᇀᄅ: "н",
    ᇀᄆ: "нм",
    ᇀᄒ: "тх",
    ᇁ: "п",
    ᇁᄋ: "пх",
    ᇁᄂ: "мн",
    ᇁᄅ: "мн",
    ᇁᄆ: "мм",
    ᇁᄒ: "пх",
    ᇂ: "т",
    ᇂᄋ: "х",
    ᇂᄂ: "н",
    ᇂᄅ: "н",
    ᇂᄆ: "мм",
    ᇂᄒ: "тх",
    ᇂᄀ: "кх",
  },
};

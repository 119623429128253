import { capitalize } from "@mui/material";
import { chinese } from "./data";

export const normalizeWord = (str) => {
  const result = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  return result.toLocaleLowerCase();
};

function chineseToBelarusian(text) {
  return normalizeWord(text)
    .split(" ")
    .map((t) => {
      const r = chinese[t.trim()] || t;

      return capitalize(r);
    })
    ?.join(" ");
}

export { chineseToBelarusian };

export const chinese = {
  а: "а",
  ai: "ай",
  an: "ань",
  ang: "ан",
  ao: "ао",
  ba: "ба",
  bai: "бай",
  ban: "бань",
  bang: "бан",
  bao: "бао",
  bei: "бэй",
  ben: "бэнь",
  beng: "бэн",
  bi: "бі",
  bian: "бень",
  biao: "бяо",
  bie: "бе",
  bin: "бінь",
  bing: "бін",
  bo: "бо",
  bu: "бу",
  ca: "ца",
  cai: "цай",
  can: "цань",
  cang: "цан",
  cao: "цао",
  ce: "цэ",
  cen: "цэнь",
  ceng: "цэн",
  ci: "цы",
  cong: "цун",
  cou: "цоў",
  cu: "цу",
  cuan: "цуань",
  cui: "цуэй",
  cun: "цунь",
  cuo: "цуо",
  cha: "ча",
  chai: "чай",
  chan: "чань",
  chang: "чан",
  chao: "чао",
  che: "чэ",
  chen: "чэнь",
  cheng: "чэн",
  chi: "чы",
  chong: "чун",
  chou: "чоў",
  chu: "чу",
  chua: "чуа",
  chuai: "чуай",
  chuan: "чуань",
  chuang: "чуан",
  chui: "чуэй",
  chun: "чунь",
  chuo: "чуо",
  da: "да",
  dai: "дай",
  dan: "дань",
  dang: "дан",
  dao: "дао",
  de: "дэ",
  dei: "дэй",
  den: "дэнь",
  deng: "дэн",
  di: "ды",
  dian: "д’ень",
  diao: "д’яо",
  die: "д’е",
  ding: "дын",
  diu: "дыў",
  dong: "дун",
  dou: "доў",
  du: "ду",
  duan: "дуань",
  dui: "дуэй",
  dun: "дунь",
  duo: "дуо",
  e: "э",
  ei: "эй",
  en: "энь",
  eng: "эн",
  er: "ар",
  fa: "фа",
  fan: "фань",
  fang: "фан",
  fei: "фэй",
  fen: "фэнь",
  feng: "фэн",
  fo: "фо",
  fou: "фоў",
  fu: "фу",
  ga: "га",
  gai: "гай",
  gan: "гань",
  gang: "ган",
  gao: "гао",
  ge: "гэ",
  gei: "гэй",
  gen: "гэнь",
  geng: "гэн",
  gong: "гун",
  gou: "гоў",
  gu: "гу",
  gua: "гуа",
  guai: "гуай",
  guan: "гуань",
  guang: "гуан",
  gui: "гуэй",
  gun: "гунь",
  guo: "гуо",
  ha: "ха",
  hai: "хай",
  han: "хань",
  hang: "хан",
  hao: "хао",
  he: "хэ",
  hei: "хэй",
  hen: "хэнь",
  heng: "хэн",
  hong: "хун",
  hou: "хоў",
  hu: "ху",
  hua: "хуа",
  huai: "хуай",
  huan: "хуань",
  huang: "хуан",
  hui: "хуэй",
  hun: "хунь",
  huo: "хуо",
  ji: "дзі",
  jia: "дзя",
  jian: "дзень",
  jiang: "дзян",
  jiao: "дзяо",
  jie: "дзе",
  jin: "дзінь",
  jing: "дзін",
  jiong: "дзюн",
  jiu: "дзіў",
  ju: "дзю",
  juan: "дзюань",
  jue: "дзюэ",
  jun: "дзюнь",
  ka: "ка",
  kai: "кай",
  kan: "кань",
  kang: "кан",
  kao: "као",
  ke: "кэ",
  kei: "кэй",
  ken: "кэнь",
  keng: "кэн",
  kong: "кун",
  kou: "коў",
  ku: "ку",
  kua: "куа",
  kuai: "куай",
  kuan: "куань",
  kuang: "куан",
  kui: "куэй",
  kun: "кунь",
  kuo: "куо",
  la: "ла",
  lai: "лай",
  lan: "лань",
  lang: "лан",
  lao: "лао",
  le: "лэ",
  lei: "лэй",
  leng: "лэн",
  li: "лі",
  lia: "ля",
  lian: "лень",
  liang: "лян",
  liao: "ляо",
  lie: "ле",
  lin: "лінь",
  ling: "лін",
  liu: "ліў",
  long: "лун",
  lou: "лоў",
  lu: "лу",
  lü: "лю",
  luan: "луань",
  lüan: "люань",
  lüe: "люэ",
  lun: "лунь",
  luo: "луо",
  ma: "ма",
  mai: "май",
  man: "мань",
  mang: "ман",
  mao: "мао",
  me: "мэ",
  mei: "мэй",
  men: "мэнь",
  meng: "мэн",
  mi: "мі",
  mian: "мень",
  miao: "мяо",
  mie: "ме",
  min: "мінь",
  ming: "мін",
  miu: "міў",
  mo: "мо",
  mou: "моў",
  mu: "му",
  na: "на",
  nai: "най",
  nan: "нань",
  nang: "нан",
  nao: "нао",
  ne: "нэ",
  nei: "нэй",
  nen: "нэнь",
  neng: "нэн",
  ni: "ні",
  nian: "нень",
  niang: "нян",
  niao: "няо",
  nie: "не",
  nin: "нінь",
  ning: "нін",
  niu: "ніў",
  nong: "нун",
  nou: "ноў",
  nu: "ну",
  nü: "ню",
  nuan: "нуань",
  nüe: "нюэ",
  nuo: "нуо",
  o: "о",
  ou: "оў",
  pa: "па",
  pai: "пай",
  pan: "пань",
  pang: "пан",
  pao: "пао",
  pei: "пэй",
  pen: "пэнь",
  peng: "пэн",
  pi: "пі",
  pian: "пень",
  piao: "пяо",
  pie: "пе",
  pin: "пінь",
  ping: "пін",
  po: "по",
  pou: "поў",
  pu: "пу",
  qi: "ці",
  qia: "ця",
  qian: "цень",
  qiang: "цян",
  qiao: "цяо",
  qie: "це",
  qin: "цінь",
  qing: "цін",
  qiong: "цюн",
  qiu: "ціў",
  qu: "цю",
  quan: "цюань",
  que: "цюэ",
  qun: "цюнь",
  ran: "жань",
  rang: "жан",
  rao: "жао",
  re: "жэ",
  ren: "жэнь",
  reng: "жэн",
  ri: "жы",
  rong: "жун",
  rou: "жоў",
  ru: "жу",
  ruan: "жуань",
  rui: "жуэй",
  run: "жунь",
  ruo: "жуо",
  sa: "са",
  sai: "сай",
  san: "сань",
  sang: "сан",
  sao: "сао",
  se: "сэ",
  sei: "сэй",
  sen: "сэнь",
  seng: "сэн",
  si: "сы",
  song: "сун",
  sou: "соў",
  su: "су",
  suan: "суань",
  sui: "суэй",
  sun: "сунь",
  suo: "суо",
  sha: "ша",
  shai: "шай",
  shan: "шань",
  shang: "шан",
  shao: "шао",
  she: "шэ",
  shen: "шэнь",
  sheng: "шэн",
  shi: "шы",
  shou: "шоў",
  shu: "шу",
  shua: "шуа",
  shuai: "шуай",
  shuan: "шуань",
  shuang: "шуан",
  shui: "шуэй",
  shun: "шунь",
  shuo: "шуо",
  ta: "та",
  tai: "тай",
  tan: "тань",
  tang: "тан",
  tao: "тао",
  te: "тэ",
  tei: "тэй",
  ten: "тэнь",
  teng: "тэн",
  ti: "ты",
  tian: "т’ень",
  tiao: "т’яо",
  tie: "т’е",
  ting: "тын",
  tong: "тун",
  tou: "тоў",
  tu: "ту",
  tuan: "туань",
  tui: "туэй",
  tun: "тунь",
  tuo: "туо",
  wa: "ва",
  wai: "вай",
  wan: "вань",
  wang: "ван",
  wei: "вэй",
  wen: "вэнь",
  weng: "вэн",
  wo: "во",
  wu: "ву",
  xi: "сі",
  xia: "ся",
  xian: "сянь",
  xiang: "сян",
  xiao: "сяо",
  xie: "се",
  xin: "сінь",
  xing: "сін",
  xiong: "сюн",
  xiu: "сіў",
  xu: "сю",
  xuan: "сюань",
  xue: "сюэ",
  xun: "сюнь",
  ya: "я",
  yan: "янь",
  yang: "ян",
  yao: "яо",
  ye: "е",
  yi: "і",
  yin: "інь",
  ying: "ін",
  yo: "ё",
  yong: "юн",
  you: "ёў",
  yu: "ю",
  yuan: "юань",
  yue: "юэ",
  yun: "юнь",
  za: "дза",
  zai: "дзай",
  zan: "дзань",
  zang: "дзан",
  zao: "дзао",
  ze: "дзэ",
  zei: "дзэй",
  zen: "дзэнь",
  zeng: "дзэн",
  zi: "дзы",
  zong: "дзун",
  zou: "дзоў",
  zu: "дзу",
  zuan: "дзуань",
  zui: "дзуэй",
  zun: "дзунь",
  zuo: "дзуо",
  zha: "джа",
  zhai: "джай",
  zhan: "джань",
  zhang: "джан",
  zhao: "джао",
  zhe: "джэ",
  zhen: "джэнь",
  zheng: "джэн",
  zhi: "джы",
  zhong: "джун",
  zhou: "джоў",
  zhu: "джу",
  zhua: "джуа",
  zhuai: "джуай",
  zhuan: "джуань",
  zhuang: "джуан",
  zhui: "джуэй",
  zhun: "джунь",
  zhuo: "джуо",
};
